import React from "react"
import { graphql } from "gatsby"
import { Styled, Box,} from "theme-ui"
import ContactSection from '../components/ContactSection'
import {Content,  ContentWithImage, ContentBlock, ContentWithImageBlock } from "../blocks/content"
import { useLocalJsonForm } from "gatsby-tinacms-json"
import Layout from "../components/layout"
import BasicHero from "../components/BasicHero"
import ShapedCard from '../components/ShapedCard'



export default function IndustryResources({ data }) {
  
  const [page] = useLocalJsonForm(data.industries, IndustryForm)
  const blocks = page !== null && page.blocks ? page.blocks : []
  return (
    <Layout>
      { page.hero !== null && page.hero.image !== null && 
        <BasicHero 
          fluid={page.hero.image.childImageSharp.fluid}  
          title={"Industry Resources Hero Image"} 
          heroTitle={page.hero.headline}
          shapeColor={'transparent'}
        />
      }
      {/* <h1>Hello world!</h1> */}
      {blocks &&
        blocks.map(({ _template, ...data }, i) => {
          switch (_template) {

          //   case "TitleBlock":
          //     // return <Title page={page} data={data} />
          //   case "ImageBlock":
          //     // return <Image data={data} />
            case "ContentWithImageBlock":
              if (data.content && page.childrenIndustriesJsonBlockMarkdown[i])
                return (
                  <ContentWithImage
                    data={data}
                    html={
                      page.childrenIndustriesJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                );
              break;
            case "ContentBlock":
              if (data.content && page.childrenIndustriesJsonBlockMarkdown[i])
                return (
                  <Content
                    data={data}
                    html={
                      page.childrenIndustriesJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                );
              break;
            default:
              return true;
          }
          return false;
        })}

      <Box as="section" bg="white" my={13} color="white">
        <ShapedCard
          sx={{color: 'white'}}
          fluidImg={data.tintRoll.childImageSharp.fluid}
          to={'/products'}
          title='Nano Ceramic Window Tint'
          text="The highest-quality, longest-lasting window protection on the market. Raptect nano ceramic window tint filters 99% of UV and 80% of infrared rays. Long-lasting and cost-effective, it protects privacy, interiors, and the windows themselves while conserving energy and controlling temperatures."/>
      </Box>

      <ContactSection title='Contact Us' alternate>
        <Box color={'white'}>
          <Styled.h2>How to Buy</Styled.h2>
          <Styled.p>Our team can help you find the solutions you need. Get in touch with us for quotes and exclusive offers.</Styled.p>
        </Box>
      </ContactSection>

    </Layout>
  )
}

const IndustryForm = {
  label: "Industry Resources",
  fields: [
    {
      label: "Title",
      name: "rawJson.title",
      component: "text",
    },
    {
      label: "Hero",
      name: "rawJson.hero",
      component: "group",
      fields: [
        {
          label: "Headline",
          name: "headline",
          component: "text",
        },
        {
          label: "Textline",
          name: "textline",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: (filename) => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues) => {
            if (!formValues.hero || !formValues.hero.image)
              return ""
            return formValues.hero.image.childImageSharp.fluid.src
          },
        },
      ],
    },
    {
      label: "Page Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: {
        ContentWithImageBlock,
        ContentBlock,
        // FormBlock,
        // ContentBlock,
      },
    },
  ],
}


export const useQuery = graphql`
  query($path: String!) {
    industries: industriesJson(path: { eq: $path }) {
      title
      hero {
        headline
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }
      blocks {
        _template
        content
        align
        background_color
        font_color
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }

      childrenIndustriesJsonBlockMarkdown {
        childMarkdownRemark {
          html
        }
      }

      rawJson
      fileRelativePath
    }
    purpleBG: file(relativePath: { eq: "purple-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tintRoll: file(relativePath: { eq: "tint-roll.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
